import React, { useState, useEffect } from 'react';
import { Image } from 'antd';
import './Gallery.scss';
import imageGallery from './modules';
import classNames from 'classnames';

export default function Gallery() {
  const [image, setImage] = useState(imageGallery());
  useEffect(() => {
    setImage(imageGallery());
  }, []);
  return (
    <div className={classNames('imageGallery')}>
      <Image.PreviewGroup className="m-3">
        {image.map((item) => (
          <Image
            className={classNames('col-sm-12', 'gallery-images')}
            width={320}
            height={300}
            src={item.image}
          />
        ))}
      </Image.PreviewGroup>
    </div>
  );
}

// ReactDOM.render(<Gallery />, mountNode);
