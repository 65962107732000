import i18n from 'i18next';
import {
  adnan,
  aida,
  abdel,
  amila,
  amna,
  melisa,
  senid,
  placeholder,
} from '../../../images';

const contactData = () => [
  {
    image: adnan,
    title: i18n.t('contact departments title'),
    nameTitle: i18n.t('contact departments name'),
    nameContent: 'Adnan Telalović',
    emailTitle: 'Email',
    email: 'adnan@att.ba',
    phoneTitle: i18n.t('contact departments phone'),
    phone: '+387 0 61 240570',
  },
  {
    image: placeholder,
    title: i18n.t('contact departments title7'),
    nameTitle: i18n.t('contact departments name'),
    nameContent: 'Edina Hajdarević',
    emailTitle: 'Email',
    email: 'edina.hajdarevic@att.ba',
    phoneTitle: i18n.t('contact departments phone'),
    phone: '+387 0 30 509178',
  },
  {
    image: amila,
    title: i18n.t('contact departments title5'),
    nameTitle: i18n.t('contact departments name'),
    nameContent: 'Amila Agić',
    emailTitle: 'Email',
    email: 'amila.agic@att.ba',
    phoneTitle: i18n.t('contact departments phone'),
    phone: '+387 0 30 509178',
  },
  {
    image: melisa,
    title: i18n.t('contact departments title4'),
    nameTitle: i18n.t('contact departments name'),
    nameContent: 'Melisa Bakić',
    emailTitle: 'Email',
    email: 'melisa.bakic@att.ba',
    phoneTitle: i18n.t('contact departments phone'),
    phone: '+387 0 30 266800',
  },
  {
    image: senid,
    title: i18n.t('contact departments title2'),
    nameTitle: i18n.t('contact departments name'),
    nameContent: 'Senid Karađuz',
    emailTitle: 'Email',
    email: 'senid.karaduz@att.ba',
    phoneTitle: i18n.t('contact departments phone'),
    phone: '+387 0 62 919143',
  },
  {
    image: abdel,
    title: i18n.t('contact departments title3'),
    nameTitle: i18n.t('contact departments name'),
    nameContent: 'Abdel Alibegović',
    emailTitle: 'Email',
    email: 'abdel.alibegovic@att.ba',
    phoneTitle: i18n.t('contact departments phone'),
    phone: '+387 0 61 460848',
  },

  {
    image: aida,
    title: i18n.t('contact departments title6'),
    nameTitle: i18n.t('contact departments name'),
    nameContent: 'Aida Alibegović',
    emailTitle: 'Email',
    email: 'aida.alibegovic@att.ba',
    phoneTitle: i18n.t('contact departments phone'),
    phone: '+387 0 30 276110',
  },

  {
    image: amna,
    title: i18n.t('contact departments title8'),
    nameTitle: i18n.t('contact departments name'),
    nameContent: 'Amna Čukurija',
    emailTitle: 'Email',
    email: 'amna.cukurija@att.ba',
    phoneTitle: i18n.t('contact departments phone'),
    phone: '+387 0 30 266588',
  },
];

export default contactData;
