import React from 'react';
import './banner.scss';
import Slide from 'react-reveal';

const Banner = (props) => {
  const {
    title,
    description,
    buttonText,
    buttonUrl,
    styleClass,
  } = props;

  return (
    <div className={styleClass}>
      <Slide bottom>
        <h1>{title}</h1>
      </Slide>
      <Slide bottom>
        {' '}
        <p>{description}</p>
      </Slide>
      <Slide bottom>
        {buttonText && <a href={buttonUrl}>{buttonText}</a>}
      </Slide>
    </div>
  );
};

export default Banner;
