import React, { useState } from 'react';
import './NavBar.scss';
import { attLogo } from '../images';
import LanguageSwitcher from '../LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { navbarItems } from './modules';
import { NavLink } from 'react-router-dom';
import { Fade as Hamburger } from 'hamburger-react';
import Fade from 'react-reveal';

const NavBar = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const closeMenu = () => setOpen(false);

  return (
    <div>
      <nav>
        <a href="/" className="logo">
          <img
            alt="ATT d.o.o."
            src={attLogo}
            className="logo-image"
            href="/"
          />
          <p className="logo-text">{t('navbar title')}</p>
        </a>
        <ul
          className="nav-links"
          style={{
            transform: open ? 'translateX(0) ' : '',
          }}
        >
          <li className="language-selector">
            <LanguageSwitcher />
          </li>
          {navbarItems().map((item) => (
            <li>
              <Fade>
                <NavLink
                  to={item.url}
                  activeStyle={{
                    borderBottom: '1px solid white',
                    padding: '7px 0px',
                  }}
                  isMobile={true}
                  closeMenu={closeMenu}
                  onClick={() => closeMenu()}
                >
                  <span>{item.title}</span>
                </NavLink>
              </Fade>
            </li>
          ))}
        </ul>
        <Hamburger
          toggled={open}
          toggle={setOpen}
          size={30}
          distance="lg"
        />
      </nav>
    </div>
  );
};

export default NavBar;
