import React, { useState, useEffect } from 'react';
import { data } from '../../modules';
import './CareerHero.scss';
import { Slide, Fade } from 'react-reveal';
import { useTranslation } from 'react-i18next';

const CareerHero = () => {
  const { t } = useTranslation();
  const [heroData, setHeroData] = useState(data);
  useEffect(() => {
    setHeroData(data);
  }, []);
  return (
    <div>
      <div className="careerHero-container">
        <div className="careerHero-row">
          <Fade>
            <img
              src={heroData.image}
              alt="career"
              className="careerHero-image"
            />
          </Fade>
        </div>
        <div className="careerHero-row">
          <Slide bottom>
            <p className="careerHero-text">{t('career hero text')}</p>
            <p className="careerHero-text">
              {t('career hero text2')}
            </p>
          </Slide>
        </div>
      </div>
    </div>
  );
};

export default CareerHero;
