import React, { useState, useEffect } from 'react';
import { heroData } from './modules';
import './HeroSection.scss';
import Slide from 'react-reveal';
import { useTranslation } from 'react-i18next';

const HeroSection = () => {
  const { t } = useTranslation();
  const [heroContent, setHeroContent] = useState(heroData);
  useEffect(() => {
    setHeroContent(heroData);
  }, []);

  return (
    <div>
      <div className="hs-container">
        <div className="hs-row">
          <Slide>
            <img
              src={heroContent.image1}
              alt=""
              className="hs-image"
            />
          </Slide>
        </div>
        <div className="hs-row">
          <Slide bottom>
            {' '}
            <h1 className="hs-title">{t('policy hero title')}</h1>
          </Slide>
          <Slide bottom>
            {' '}
            <p className="hs-text1">{t('policy hero text')}</p>
          </Slide>
          <Slide bottom>
            {' '}
            <p className="hs-text2">{t('policy hero text2')}</p>
          </Slide>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
