import React, { useState, useEffect } from 'react';
import { Image } from 'antd';
import certificationImages from './modules';
import './Certification.scss';
import classNames from 'classnames';

const Certification = () => {
  const [image, setImage] = useState(certificationImages());
  useEffect(() => {
    setImage(certificationImages());
  }, []);
  return (
    <div className="certification-wrapper">
      <h1 className="certification-heading">Certifikati</h1>
      <Image.PreviewGroup className="m-3">
        {image.map((item) => (
          <Image
            className={classNames(
              'col-sm-12',
              'certification-images',
            )}
            width={320}
            height={400}
            src={item.image}
          />
        ))}
      </Image.PreviewGroup>
    </div>
  );
};

export default Certification;
