import {
  glodani1,
  glodani2,
  glodani3,
  glodani4,
  glodani5,
  glodani6,
  glodani7,
  glodani8,
  glodani9,
  glodani10,
  glodani11,
  glodani12,
  ostali1,
  ostali2,
  ostali3,
  ostali4,
  ostali5,
  ostali6,
  ostali7,
  ostali8,
  ostali9,
  ostali10,
  ostali11,
  ostali12,
  ostali13,
  ostali14,
  strugani1,
  strugani2,
  strugani3,
  strugani4,
  strugani5,
  strugani6,
  strugani7,
  strugani8,
  strugani10,
  strugani11,
  strugani12,
  strugani13,
  strugani14,
  strugani15,
  strugani16,
  strugani17,
  strugani18,
  strugani19,
  strugani20,
  strugani21,
  strugani22,
  strugani23,
  strugani24,
  strugani25,
  strugani26,
} from '../../images';

export const glodaniDijelovi = () => [
  {
    image: glodani1,
  },
  {
    image: glodani2,
  },
  {
    image: glodani3,
  },
  {
    image: glodani4,
  },
  {
    image: glodani5,
  },
  {
    image: glodani6,
  },
  {
    image: glodani7,
  },
  {
    image: glodani8,
  },
  {
    image: glodani9,
  },
  {
    image: glodani10,
  },
  {
    image: glodani11,
  },
  {
    image: glodani12,
  },
];

export const ostaliDijelovi = () => [
  {
    image: ostali1,
  },
  {
    image: ostali2,
  },
  {
    image: ostali3,
  },
  {
    image: ostali4,
  },
  {
    image: ostali5,
  },
  {
    image: ostali6,
  },
  {
    image: ostali7,
  },
  {
    image: ostali8,
  },
  {
    image: ostali9,
  },
  {
    image: ostali10,
  },
  {
    image: ostali12,
  },
  {
    image: ostali13,
  },
  {
    image: ostali11,
  },
  {
    image: ostali14,
  },
];

export const struganiDijelovi = () => [
  {
    image: strugani1,
  },
  {
    image: strugani2,
  },
  {
    image: strugani3,
  },
  {
    image: strugani3,
  },
  {
    image: strugani4,
  },
  {
    image: strugani5,
  },
  {
    image: strugani6,
  },
  {
    image: strugani7,
  },
  {
    image: strugani8,
  },
  {
    image: strugani10,
  },
  {
    image: strugani11,
  },
  {
    image: strugani12,
  },
  {
    image: strugani13,
  },
  {
    image: strugani14,
  },
  {
    image: strugani15,
  },
  {
    image: strugani16,
  },
  {
    image: strugani17,
  },
  {
    image: strugani18,
  },
  {
    image: strugani19,
  },
  {
    image: strugani20,
  },
  {
    image: strugani22,
  },
  {
    image: strugani23,
  },
  {
    image: strugani24,
  },
  {
    image: strugani25,
  },
  {
    image: strugani26,
  },
  {
    image: strugani21,
  },
];

export default { struganiDijelovi, glodaniDijelovi, ostaliDijelovi };
