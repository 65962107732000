import React from 'react';
import Gallery from './components/Gallery';
import Banner from '../Banner';
import ClientSection from '../ClientSection';
import { bannerGallerySmall } from '../Banner/modules';
import { useTranslation } from 'react-i18next';

const GalleryPage = () => {
  const { t } = useTranslation();
  return (
    <div>
      {bannerGallerySmall().map((item) => (
        <Banner {...item} />
      ))}
      <Gallery />
      <ClientSection />
    </div>
  );
};

export default GalleryPage;
