import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import translationBOS from './locales/bos/translation.json';
import translationENG from './locales/eng/translation.json';

const resources = {
  bos: {
    translation: translationBOS,
  },
  eng: {
    translation: translationENG,
  },
};
i18n.locale = 'bos';
i18n
  .use(Backend)
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'bos',
    fallbackLng: 'bos',

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
