import React from 'react';
import './Development.scss';
import { strategyImg } from '../../../images';
import Fade from 'react-reveal';
import { useTranslation } from 'react-i18next';

const Development = () => {
  const { t } = useTranslation();

  return (
    <div className="development-container">
      <Fade bottom>
        <div className="development-row">
          <img
            src={strategyImg}
            alt="strategy"
            className="strategy-image"
          />
        </div>
      </Fade>
      <Fade bottom>
        <div className="development-row">
          <h1 className="text-heading">
            {t('homepage development title')}
          </h1>
          <p className="text1">{t('homepage development text')}</p>
          <p className="text2">{t('homepage development text2')}</p>
        </div>
      </Fade>
    </div>
  );
};

export default Development;
