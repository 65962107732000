import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import './HeroSlider.scss';
import { sliderContent } from './modules';

const HeroSlider = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: true,
    adaptiveHeight: true,
    arrows: false,
  };

  return (
    <div className="hero-container">
      <Slider {...settings}>
        <div className="slide-content">
          <img
            src={sliderContent.slider1}
            alt="slider1"
            className="slider-image"
          />
          <div className="content">
            <h1>{t('homepage slider title')} </h1>
            <p>{t('homepage slider text')} </p>
          </div>
        </div>
        <div className="slide-content">
          <img
            src={sliderContent.slider2}
            alt="slider1"
            className="slider-image"
          />
          <div className="content">
            <h1>{t('homepage slider title')}</h1>
            <p>{t('homepage slider text')} </p>
          </div>
        </div>
        <div className="slide-content">
          <img
            src={sliderContent.slider3}
            alt="slider1"
            className="slider-image"
          />
          <div className="content">
            <h1>{t('homepage slider title')}</h1>
            <p>{t('homepage slider text')}</p>
          </div>
        </div>
        <div className="slide-content">
          <img
            src={sliderContent.slider4}
            alt="slider1"
            className="slider-image"
          />
          <div className="content">
            <h1>{t('homepage slider title')}</h1>
            <p>{t('homepage slider text')} </p>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default HeroSlider;
