import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Banner from '../Banner';
import { bannerReferenceSmall } from '../Banner/modules';
import Clients from './components/Clients';

const References = () => {
  const { t } = useTranslation();
  return (
    <div>
      {bannerReferenceSmall().map((item) => (
        <Banner {...item} />
      ))}
      <Clients />
    </div>
  );
};

export default References;
