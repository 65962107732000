import i18n from 'i18next';

import {
  services1,
  services2,
  services3,
  services4,
} from '../../../../images';

const service = () => [
  {
    index: 1,
    image: services1,
    title: i18n.t('homepage service1 title'),
    text: i18n.t('homepage service1 text'),
  },
  {
    index: 2,
    image: services2,
    title: i18n.t('homepage service2 title'),
    text: i18n.t('homepage service2 text'),
  },
  {
    index: 3,
    image: services3,
    title: i18n.t('homepage service3 title'),
    text: i18n.t('homepage service3 text'),
  },
  {
    index: 4,
    image: services4,
    title: i18n.t('homepage service4 title'),
    text: i18n.t('homepage service4 text'),
  },
];

export default service;
