import {
  certification1,
  certification2,
  certification3,
  certification4,
  certification5,
  certification6,
} from '../../../../images';

const certificationImages = () => [
  { image: certification1 },
  { image: certification2 },
  { image: certification3 },
  { image: certification4 },
  { image: certification5 },
  { image: certification6 },
];

export default certificationImages;
