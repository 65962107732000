import React from 'react';
import service from './modules';
import './Services.scss';
import Fade from 'react-reveal';
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { t } = useTranslation();

  return (
    <div className="services">
      <div className="container">
        {service().map((item) => (
          <Fade bottom>
            <div className="box" key={item.index}>
              <img
                src={item.image}
                alt="services"
                className="service-img"
              />
              <div className="title-container">
                <h5 className="title">{item.title}</h5>
              </div>
              <div className="break-line" />
              <p className="text">{item.text}</p>
            </div>
          </Fade>
        ))}
      </div>
    </div>
  );
};

export default Services;

// col-12 col-md-6 mt-5
