import React from 'react';
import ClientSection from '../ClientSection';
import CareerHero from './components/CareerHero';
import CareerForm from './components/CareerForm';

import Banner from '../Banner';
import { bannerAboutUsSmall } from '../Banner/modules';
import { useTranslation } from 'react-i18next';

const Career = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="banner-wrapper">
        {bannerAboutUsSmall().map((item) => (
          <Banner {...item} />
        ))}{' '}
      </div>
      <div className="career-wrapper"></div>
      <CareerHero />
      <CareerForm />
      <ClientSection />
    </div>
  );
};

export default Career;
