import React from 'react';
import { useTranslation } from 'react-i18next';
import ContactCard from './ContactCard';
import contactData from './modules';

const Departments = () => {
  const { t } = useTranslation();
  return (
    <div className="department-container">
      {contactData().map((item) => (
        <ContactCard {...item} />
      ))}
    </div>
  );
};

export default Departments;
