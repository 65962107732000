import i18n from 'i18next';

const mData = () => [
  {
    index: 1,
    title: i18n.t('homepage mission1 title'),
    text: i18n.t('homepage mission1 text'),
  },
  {
    index: 2,

    title: i18n.t('homepage mission2 title'),
    text: i18n.t('homepage mission2 text'),
  },
  {
    index: 3,
    title: i18n.t('homepage mission3 title'),
    text: i18n.t('homepage mission3 text'),
  },
];

export default mData;
