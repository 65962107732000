import {
  corghi2,
  gorenje2,
  linde2,
  mahle2,
  manhummel2,
  shaffler2,
  volkswagen2,
} from '../../images';

const clientSlider = () => [
  { image: corghi2 },
  { image: gorenje2 },
  { image: linde2 },
  { image: mahle2 },
  { image: manhummel2 },
  { image: shaffler2 },
  { image: volkswagen2 },
];

export default clientSlider;
