import React from 'react';
import { contactImg } from '../../images';
import './FormWrapper.scss';
import Form from '../Form';
import Fade from 'react-reveal';

const FormWrapper = () => {
  return (
    <div>
      <div className="form-container">
        <div className="form-row">
          <Form />
        </div>
        <div className="form-row">
          <Fade>
            <img src={contactImg} alt="" className="fw-img" />
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default FormWrapper;
