import React from 'react';
import { useTranslation } from 'react-i18next';
import { bos, eng } from '../images';
import Select from 'react-select';
import './LanguageSwitcher.scss';

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const switchLanguage = (language) => {
    i18n.changeLanguage(language.value);
  };

  const languageOptions = [
    {
      value: 'bos',
      label: (
        <div className="lang-optionItem">
          <img src={bos} alt="bos" />
          <h5>BOS</h5>
        </div>
      ),
    },
    {
      value: 'eng',
      label: (
        <div className="lang-optionItem">
          <img src={eng} alt="eng" />
          <h5>ENG</h5>
        </div>
      ),
    },
  ];

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.8,
      },
    }),
    option: (base, state) => ({
      ...base,
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.6,
      },
      display: state.isSelected ? 'none' : 'block',
    }),
    menu: (base) => ({
      ...base,
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px',
    }),
    menuList: (base) => ({
      ...base,
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px',
    }),
  };

  return (
    <Select
      options={languageOptions}
      isSearchable={false}
      placeholder={false}
      className="lang-select"
      value={languageOptions.find(
        (obj) => obj.value === i18n.language,
      )}
      onChange={switchLanguage}
      styles={style}
      components={{
        IndicatorSeparator: () => null,
      }}
    ></Select>
  );
}
export default LanguageSwitcher;
