import React from 'react';
import './Footer.scss';
import navbarItems from '../NavBar/modules';
import { attLogo } from '../images';
import { FacebookIcon, LinkedinIcon } from 'react-share';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer-container">
      <div className="footer-div">
        <a href="/" className="logo">
          <img
            alt="ATT d.o.o."
            src={attLogo}
            className="footer-image"
          />
          <p>{t('navbar title')}</p>
        </a>
        <ul className="footer-nav-links">
          {navbarItems().map((item) => {
            return (
              <li key={item.index}>
                <a className={item.cName} href={item.url}>
                  {item.title}
                </a>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="footer-div-second">
        <p>{t('footer adress')}</p>
        <p>
          {t('footer phoneTitle')}
          <a href={t('footer phone1 href')}>{t('footer phone1')}</a>
        </p>
        <p>
          <a href={t('footer mail href')}>{t('footer mail')}</a>
        </p>
        <p>
          <a
            href={t('footer facebook href')}
            className="footer-icons"
          >
            <FacebookIcon size={30} round={true} />
          </a>

          <a
            href={t('footer linkedin href')}
            className="footer-icons"
          >
            <LinkedinIcon size={30} round={true} />
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
