import i18n from 'i18next';

export const navbarItems = () => [
  {
    index: 1,
    title: i18n.t('navbar tab career'),
    url: '/karijera',
    cName: 'nav-links',
  },
  {
    index: 2,
    title: i18n.t('navbar tab policy'),
    url: '/politika',
    cName: 'nav-links',
  },
  {
    index: 3,
    title: i18n.t('navbar tab references'),
    url: '/reference',
    cName: 'nav-links',
  },
  {
    index: 4,
    title: i18n.t('navbar tab products'),
    url: '/proizvodi',
    cName: 'nav-links',
  },
  {
    index: 5,
    title: i18n.t('navbar tab gallery'),
    url: '/galerija',
    cName: 'nav-links',
  },
  {
    index: 5,
    title: i18n.t('navbar tab contact'),
    url: '/kontakt',
    cName: 'nav-links',
  },
];

export default navbarItems;
