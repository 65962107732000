import i18n from 'i18next';

export const bannerHomeBig = () => [
  {
    title: i18n.t('banner homeBig title'),
    description: i18n.t('banner homeBig description'),
    styleClass: 'bannerHomeBig',
  },
];

export const bannerHomeSmall = () => [
  {
    title: i18n.t('banner homeSmall title'),
    description: i18n.t('banner homeSmall description'),
    buttonText: i18n.t('banner homeSmall buttonText'),
    buttonUrl: '/proizvodi',
    styleClass: 'bannerHomeSmall',
  },
];

export const bannerPolicyBig = () => [
  {
    title: i18n.t('banner policyBig title'),
    description: i18n.t('banner policyBig description'),
    styleClass: 'bannerPolicyBig',
  },
];
export const bannerProductsSmall = () => [
  {
    title: i18n.t('banner productsSmall title'),
    description: i18n.t('banner productsSmall description'),
    styleClass: 'bannerProductsSmall',
  },
];
export const bannerAboutUsSmall = () => [
  {
    title: i18n.t('banner aboutUsSmall title'),
    description: i18n.t('banner aboutUsSmall description'),
    styleClass: 'bannerAboutUsSmall',
  },
];

export const bannerContactSmall = () => [
  {
    title: i18n.t('banner contactSmall title'),
    description: i18n.t('banner contactSmall description'),
    styleClass: 'bannerContactSmall',
  },
];
export const bannerReferenceSmall = () => [
  {
    title: i18n.t('banner referenceSmall title'),
    description: i18n.t('banner referenceSmall description'),
    styleClass: 'bannerReferenceSmall',
  },
];

export const bannerGallerySmall = () => [
  {
    title: i18n.t('banner gallerySmall title'),
    description: i18n.t('banner gallerySmall description'),
    styleClass: 'bannerGallerySmall',
  },
];

export default {
  bannerHomeBig,
  bannerHomeSmall,
  bannerPolicyBig,
  bannerProductsSmall,
  bannerAboutUsSmall,
  bannerContactSmall,
  bannerReferenceSmall,
  bannerGallerySmall,
};
