import React from 'react';
import './Mission.scss';
import { missionImg } from '../../../images';
import mData from './modules';
import Slide from 'react-reveal';
import { useTranslation } from 'react-i18next';

const Mission = () => {
  const { t } = useTranslation();

  return (
    <div className="mission-container">
      <Slide>
        <div className="img-container">
          <img src={missionImg} alt="" className="mission-image" />
        </div>
      </Slide>
      <div className="mission">
        {mData().map((item) => (
          <div className="mission-cont">
            <h5 className="mission-heading">{item.title}</h5>
            <div className="break-line2" />
            <p className="mission-paragraph">{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Mission;
