import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import './ClientSection.scss';
import SliderCard from './SliderCard';
import Fade from 'react-reveal';

import clientSlider from './modules/sliderData';
import { useTranslation } from 'react-i18next';

const ClientSection = () => {
  const { t } = useTranslation();
  const [sliderContent, setSliderContent] = useState(clientSlider());

  useEffect(() => {
    setSliderContent(clientSlider());
  }, []);

  const settings = {
    pauseOnHover: true,
    autoplaySpeed: 3500,
    infinite: true,
    speed: 3500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="client-container">
      <Fade>
        <div className="client-content">
          <h1 className="client-heading">
            {t('clientSection title')}
          </h1>
          <p className="client-text">{t('clientSection text')}</p>
          <p className="client-text2">{t('clientSection text2')}</p>
          <a href="/reference" className="client-button">
            {t('clientSection buttonText')}
          </a>
        </div>

        <div className="slider-wrapper">
          <Slider {...settings} className="client-slider">
            {sliderContent.map((slider) => (
              <SliderCard {...slider} />
            ))}
          </Slider>
        </div>
      </Fade>
    </div>
  );
};

export default ClientSection;
