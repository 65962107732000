import i18n from 'i18next';
import { policyAtt } from '../../../../images';

export const clientSatisfactionData = {
  image: policyAtt,
};

export const clientSatisfactionList = () => [
  {
    list: i18n.t('policy clientSatisfaction list'),
  },
  {
    list: i18n.t('policy clientSatisfaction list2'),
  },
  {
    list: i18n.t('policy clientSatisfaction list3'),
  },
  { list: i18n.t('policy clientSatisfaction list4') },
  {
    list: i18n.t('policy clientSatisfaction list5'),
  },
];

export default { clientSatisfactionData, clientSatisfactionList };
