import React from 'react';
import Fade from 'react-reveal';
import HeroSlider from './components/HeroSlider';
import Services from './components/Services';
import Mission from './components/Mission';
import Development from './components/Development';
import ClientSection from '../ClientSection';
import Banner from '../Banner';
import { bannerHomeSmall } from '../Banner/modules';
import { bannerHomeBig } from '../Banner/modules';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Fade>
        <HeroSlider />
      </Fade>
      <Services />
      {bannerHomeSmall().map((item) => (
        <Banner {...item} />
      ))}
      <Mission />
      {bannerHomeBig().map((item) => (
        <Banner {...item} />
      ))}
      <Development />
      <ClientSection />
    </div>
  );
};

export default Home;
