import React from 'react';
import './ClientSection.scss';

const SliderCard = (props) => {
  const { image } = props;
  return (
    <div className="slider-card">
      <img src={image} alt="slider" className="client-image" />
    </div>
  );
};

export default SliderCard;
