import React, { useState, useEffect } from 'react';
import clientSlider from '../../../ClientSection/modules/sliderData';
import SliderCard from '../../../ClientSection/SliderCard';
import './Client.scss';
import { Slide, Fade } from 'react-reveal';
import { useTranslation } from 'react-i18next';

const Clients = () => {
  const { t } = useTranslation();
  const [sliderContent, setSliderContent] = useState(clientSlider());

  useEffect(() => {
    setSliderContent(clientSlider());
  }, []);
  return (
    <>
      <div className="client-container">
        <div className="client-content">
          <Fade>
            <h1 className="client-heading">
              {t('clientSection title')}
            </h1>
            <p className="client-text">{t('clientSection text')}</p>
          </Fade>
        </div>

        <div className="image-wrapper">
          <Slide bottom>
            {sliderContent.map((slider) => (
              <SliderCard {...slider} className="image-client" />
            ))}
          </Slide>
        </div>
      </div>
    </>
  );
};

export default Clients;
