import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal';
import './ClientSatisfaction.scss';

import { clientSatisfactionData } from './modules';
import { clientSatisfactionList } from './modules';

const ClientSatisfaction = () => {
  const { t } = useTranslation();
  const [content, setContent] = useState(clientSatisfactionData);

  useEffect(() => {
    setContent(clientSatisfactionData);
  }, []);

  return (
    <div>
      <div className="cs-container">
        <div className="cs-row">
          <Fade>
            <h1 className="cs-title">
              {t('policy clientSatisfaction title')}
            </h1>
          </Fade>
          <Fade bottom>
            {clientSatisfactionList().map((item) => (
              <p className="cs-list">{item.list}</p>
            ))}
          </Fade>
        </div>
        <div className="cs-row">
          <Fade>
            <img src={content.image} alt="" className="cs-image" />
          </Fade>
          <Fade bottom>
            <p className="cs-text">
              {t('policy clientSatisfaction text')}
            </p>
            <p className="cs-text">
              {t('policy clientSatisfaction text2')}
            </p>
            <p className="cs-text2">
              {t('policy clientSatisfaction text3')}
            </p>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default ClientSatisfaction;
