import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'antd';
import classNames from 'classnames';

import Banner from '../Banner';
import ClientSection from '../ClientSection';
import { bannerProductsSmall } from '../Banner/modules';
import './ProductPage.scss';
import {
  glodaniDijelovi,
  struganiDijelovi,
  ostaliDijelovi,
} from './modules';

const Products = () => {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);

  return (
    <div>
      {bannerProductsSmall().map((item) => (
        <Banner {...item} />
      ))}

      <div className="gallery-wrapper">
        <button
          onClick={() => {
            setPage(0);
          }}
          type="button"
          className="btn btn-secondary btn-lg btn-block"
        >
          {t('product buttonText 1')}
        </button>
        <button
          onClick={() => {
            setPage(1);
          }}
          type="button"
          className="btn btn-secondary btn-lg btn-block"
        >
          {t('product buttonText 2')}
        </button>
        <button
          onClick={() => {
            setPage(2);
          }}
          type="button"
          className="btn btn-secondary btn-lg btn-block"
        >
          {t('product buttonText 3')}
        </button>
      </div>

      {page === 0 && (
        <div className="product-container">
          <Image.PreviewGroup className="m-3">
            {struganiDijelovi().map((item) => (
              <Image
                className={classNames('col-sm-12', 'product-images')}
                width={300}
                height={300}
                src={item.image}
              />
            ))}
          </Image.PreviewGroup>
        </div>
      )}

      {page === 1 && (
        <div className="product-container">
          <Image.PreviewGroup className="m-3">
            {glodaniDijelovi().map((item) => (
              <Image
                className={classNames('col-sm-12', 'product-images')}
                width={300}
                height={300}
                src={item.image}
              />
            ))}
          </Image.PreviewGroup>
        </div>
      )}

      {page === 2 && (
        <div className="product-container">
          <Image.PreviewGroup className="m-3">
            {ostaliDijelovi().map((item) => (
              <Image
                className={classNames('col-sm-12', 'product-images')}
                width={300}
                height={300}
                src={item.image}
              />
            ))}
          </Image.PreviewGroup>
        </div>
      )}
      <ClientSection />
    </div>
  );
};

export default Products;
