import { BrowserRouter as Router, Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import '../../index.scss';
import './App.scss';

import NavBar from '../NavBar';
import Footer from '../Footer';
import Home from '../Home';
import Career from '../Career';
import Policy from '../Policy';
import References from '../References';
import Products from '../ProductPage';
import Contact from '../Contact';
import GalleryPage from '../Gallery';

const routes = [
  { path: '/', name: 'Home', Component: Home },
  { path: '/karijera', name: 'Career', Component: Career },
  { path: '/politika', name: 'Policy', Component: Policy },
  { path: '/reference', name: 'References', Component: References },
  { path: '/proizvodi', name: 'Products', Component: Products },
  { path: '/kontakt', name: 'Contact', Component: Contact },
  { path: '/galerija', name: 'Gallery', Component: GalleryPage },
];

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <div className="route-container">
          {routes.map(({ path, Component }) => (
            <Route key={path} exact path={path}>
              {({ match }) => (
                <CSSTransition
                  in={match != null}
                  timeout={300}
                  classNames="page"
                  unmountOnExit
                >
                  <Component />
                </CSSTransition>
              )}
            </Route>
          ))}
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
