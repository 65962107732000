import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Fade from 'react-reveal';

import './Form.scss';

import { init, sendForm } from 'emailjs-com';
import { useTranslation } from 'react-i18next';

init('user_DclPhT3GCuwQ44CuT79Tq');

const Form = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    sendForm('service_qg351i8', 'template_6wqm67p', '#contact-form');
    setMessage(t('contact form successMessage'));
  };

  return (
    <div className="form">
      <div className="form-cont">
        <Fade>
          <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
            <h1 className="form-heading">
              {t('contact form title')}
            </h1>
            <p className="form-title">{t('contact form email')}</p>
            <input
              className="form-input"
              type="email"
              placeholder="E-mail"
              {...register('E-mail', {
                required: { value: true, message: 'Unesite e-mail' },
                pattern:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
            <p className="form-title">{t('contact form message')}</p>
            <textarea
              rows="5"
              className="form-textarea"
              placeholder={t('contact form message placeholder')}
              {...register('Poruka', {
                required: { value: true, message: 'Unesite poruku' },
                maxLength: 600,
              })}
            />
            <div className="button-div">
              <p className="contactSuccess-message">{message}</p>
              <button
                type="submit"
                onSubmit={onSubmit}
                className="btn btn-primary"
              >
                {t('contact form buttonText')}
              </button>
            </div>
          </form>
        </Fade>
      </div>
    </div>
  );
};

export default Form;
