import React from 'react';
import './Departments.scss';
import Fade from 'react-reveal';

const ContactCard = (props) => {
  const {
    image,
    title,
    nameTitle,
    nameContent,
    emailTitle,
    email,
    phoneTitle,
    phone,
  } = props;
  return (
    <div>
      <Fade bottom>
        <figure className="snip1376">
          <img src={image} alt="sample17" />
          <figcaption>
            <h2>{}</h2>
            <h1 className="contact-title">{title}</h1>
            <h3 className="contact-heading">{nameTitle}</h3>
            <p className="contact-text">{nameContent}</p>
            <h3 className="contact-heading">{emailTitle}</h3>
            <p className="contact-text">{email}</p>
            <h3 className="contact-heading">{phoneTitle}</h3>
            <p className="contact-text">{phone}</p>
          </figcaption>
        </figure>
      </Fade>
    </div>
  );
};

export default ContactCard;
