import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Fade from 'react-reveal';
import classNames from 'classnames';

import './CareerForm.scss';

import { init, sendForm } from 'emailjs-com';
import { useTranslation } from 'react-i18next';
init('user_DclPhT3GCuwQ44CuT79Tq');

export default function CareerForm() {
  const { t } = useTranslation();

  const [formStep, setFormStep] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: 'all' });

  const completeFormStep = () => {
    setFormStep((cur) => cur + 1);
  };
  const submitForm = () => {
    sendForm('service_qg351i8', 'template_h5s7u07', '#career-form');
    completeFormStep();
  };

  const renderButton = () => {
    if (formStep > 2) {
      return undefined;
    } else if (formStep === 2) {
      return (
        <button
          onClick={submitForm}
          disabled={!isValid}
          className="btn btn-primary mb-3"
        >
          {t('career form buttonText')}
        </button>
      );
    } else {
      return (
        <button
          onClick={completeFormStep}
          disabled={!isValid}
          className="btn btn-primary mb-3"
        >
          {t('career form nextStep')}
        </button>
      );
    }
  };

  return (
    <div className="careerForm-wrapper">
      <Fade>
        <form
          className=""
          id="career-form"
          onSubmit={handleSubmit(submitForm)}
        >
          <p className="careerForm-heading">
            {t('career form title')}
          </p>
          <div className="careerForm-container">
            {formStep >= 0 && (
              <section
                className={formStep === 0 ? 'd-block' : 'd-none'}
              >
                <div className="row">
                  <div className="col-md-5">
                    {' '}
                    <p className="careerForm-text">
                      {t('career form date')}
                    </p>
                    <input
                      className="form-control mb-3"
                      type="datetime-local"
                      {...register('date', {
                        required: {
                          value: true,
                        },
                        maxLength: 80,
                      })}
                    />
                  </div>
                  <div className="col-md-7">
                    <p className="careerForm-text">
                      {t('career form position')}
                    </p>
                    <input
                      className="form-control mb-3"
                      type="text"
                      {...register('position', {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                  </div>
                </div>
                <p className="careerForm-title">
                  {t('career form personalDataTitle')}
                </p>
                <p className="careerForm-text">
                  {t('career form name')}
                </p>
                <input
                  type="text"
                  className="form-control mb-3"
                  {...register('name', {
                    required: true,
                    maxLength: 80,
                  })}
                />
                <div className="row">
                  <div className="col-md-6">
                    <p className="careerForm-text">
                      {t('career form birthDate')}
                    </p>
                    <input
                      type="text"
                      className="form-control mb-3"
                      {...register('birthDate', {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                  </div>
                  <div className="col-md-6">
                    <p className="careerForm-text">
                      {t('career form citizenship')}
                    </p>
                    <input
                      type="text"
                      className="form-control mb-3"
                      {...register('citizenship', { required: true })}
                    />{' '}
                  </div>{' '}
                </div>
                <p className="careerForm-text">
                  {t('career form adress')}
                </p>
                <input
                  type="text"
                  className="form-control mb-3"
                  {...register('adress', { required: true })}
                />
                <p className="careerForm-text">
                  {t('career form phone')}
                </p>
                <input
                  type="tel"
                  className="form-control mb-3"
                  {...register('phone', { required: true })}
                />
                <p className="careerForm-text">
                  {t('career form email')}
                </p>
                <input
                  type="email"
                  className="form-control mb-3"
                  {...register('email', {
                    required: true,
                    pattern:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                />
              </section>
            )}
            {formStep >= 1 && (
              <section
                className={formStep === 1 ? 'd-block' : 'd-none'}
              >
                {' '}
                <p className="careerForm-title">
                  {t('career form educationTitle')}
                </p>
                <p className="careerForm-text">
                  {t('career form qualifications')}
                </p>
                <input
                  type="text"
                  className="form-control mb-3"
                  {...register('profession', {
                    required: true,
                  })}
                />
                <p className="careerForm-text">
                  {t('career form courses')}
                </p>
                <textarea
                  className="form-control mb-3"
                  {...register('courses', {
                    required: true,
                  })}
                />
                <div className="careerForm-experience1">
                  <p className="careerForm-title">
                    {t('career form experience')}
                  </p>
                  <label className="careerForm-text">
                    {t('career form checkboxYes')}
                    <input
                      {...register('experience', { required: true })}
                      type="radio"
                      value="Da"
                    />
                  </label>
                  <label className="careerForm-text">
                    {t('career form checkboxNo')}
                    <input
                      {...register('experience', { required: true })}
                      type="radio"
                      value="Ne"
                    />
                  </label>
                </div>
                <p className="careerForm-text">
                  {t('career form lastJobs')}
                </p>
                <textarea
                  className="form-control mb-3"
                  {...register('lastJobs')}
                />
                <p className="careerForm-text">
                  {t('career form work')}
                </p>
                <textarea
                  className="form-control mb-3"
                  {...register('work')}
                />
                <p className="careerForm-text">
                  {t('career form positions')}
                </p>
                <textarea
                  className="form-control mb-3"
                  {...register('positions')}
                />
              </section>
            )}
            {formStep >= 2 && (
              <section
                className={formStep === 2 ? 'd-block' : 'd-none'}
              >
                <p className="careerForm-title">
                  {t('career form skillsTitle')}
                </p>
                <div className="careerForm-experience">
                  <p className="careerForm-text">
                    {t('career form computer')}
                  </p>
                  <label className="careerForm-text">
                    {t('career form checkboxYes')}
                    <input
                      {...register('computer', { required: true })}
                      type="radio"
                      value="Da"
                    />
                  </label>
                  <label className="careerForm-text">
                    {t('career form checkboxNo')}
                    <input
                      {...register('computer', { required: true })}
                      type="radio"
                      value="Ne"
                    />
                  </label>
                </div>
                <input
                  type="text"
                  className="form-control mb-3"
                  {...register('computerText')}
                />
                <div className="careerForm-experience">
                  <p className="careerForm-text">
                    {t('career form languages')}
                  </p>
                  <label className="careerForm-text">
                    {t('career form checkboxYes')}
                    <input
                      {...register('languages', { required: true })}
                      type="radio"
                      value="Da"
                    />
                  </label>
                  <label className="careerForm-text">
                    {t('career form checkboxNo')}
                    <input
                      {...register('languages', { required: true })}
                      type="radio"
                      value="Ne"
                    />
                  </label>
                </div>
                <input
                  type="text"
                  className="form-control mb-3"
                  {...register('languaguesText')}
                />
                <div className="careerForm-experience">
                  <p className="careerForm-text">
                    {t('career form drivingLicense')}
                  </p>
                  <label className="careerForm-text">
                    {t('career form checkboxYes')}
                    <input
                      {...register('drivingLicense', {
                        required: true,
                      })}
                      type="radio"
                      value="Da"
                    />
                  </label>
                  <label className="careerForm-text">
                    {t('career form checkboxNo')}
                    <input
                      {...register('drivingLicense', {
                        required: true,
                      })}
                      type="radio"
                      value="Ne"
                    />
                  </label>
                </div>
                <input
                  type="text"
                  className="form-control mb-3"
                  {...register('drivingLicenseText')}
                />
                <p className="careerForm-text">
                  {t('career form other')}
                </p>
                <textarea
                  className="form-control mb-3"
                  {...register('other')}
                />
                <p className="careerForm-title">
                  {t('career form note')}
                </p>
                <textarea
                  className="form-control mb-3"
                  {...register('note')}
                />
              </section>
            )}
            {formStep === 3 && (
              <section>
                <p className={classNames('message', 'mb-5 fw-100')}>
                  {t('career form message')}
                </p>
              </section>
            )}
            <div className="d-flex">
              {formStep > 0 && (
                <button
                  onClick={() => setFormStep((cur) => cur - 1)}
                  className={classNames(
                    'btn btn-secondary mr-3 mb-3',
                    formStep === 3 ? 'd-none' : 'd-block',
                  )}
                >
                  {t('career form previousStep')}
                </button>
              )}
              {renderButton()}
            </div>
          </div>
        </form>
      </Fade>
    </div>
  );
}
