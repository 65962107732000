import {
  slider1,
  slider2,
  slider3,
  slider4,
} from '../../../../images';
export const sliderContent = {
  slider1,
  slider2,
  slider3,
  slider4,
};

export default { sliderContent };
