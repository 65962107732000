import React, { useState, useEffect } from 'react';
import Departments from './Departments';
import FormWrapper from './FormWrapper';
import Banner from '../Banner';
import { bannerContactSmall } from '../Banner/modules';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div>
      {bannerContactSmall().map((item) => (
        <Banner {...item} />
      ))}
      <FormWrapper />
      <Departments />
    </div>
  );
};

export default Contact;
